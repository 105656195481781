import {EventBus} from "./event-bus";

window.Vue = require('vue');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Vue.component('frontend-map', require('./components/FrontendMap').default);
Vue.component('backend-map', require('./components/BackendMap').default);

import VueSweetalert2 from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

const app = new Vue({
   el: '#app'
});

window.initMap = function () {
    EventBus.$emit('maps-loaded');
}

window.getMeta = function(metaName) {

}
