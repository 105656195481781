<template>
    <div>
        <div id="map"></div>

        <div class="tour-popup" v-if="activeMarker !== null">
            <div class="container-section popup-header" id="header">
                <div class="wrapper">

                    <div class="content-middle text-center">
                        <h1>{{activeMarker.content_language.title}}</h1>
                    </div>

                    <div class="close-popup" @click="activeMarker = null">
                        <i class="icon icon-cross"></i>
                    </div>
                </div>
            </div>

            <div class="container-section popup-body" id="body">
                <div class="wrapper">

                    <div v-if="activeMarker.content_language.preview" class="content-middle" v-html="activeMarker.content_language.preview"></div>

                    <div v-if="activeMarker.gallery.length > 0" class="content-full">
                        <div style="margin: 25px 0;">
                            <vueper-slides
                                class="no-shadow"
                                :visible-slides="4"
                                slide-multiple
                                :gap="3"
                                :slide-ratio="1 / 4"
                                :dragging-distance="200"
                                :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }">
                                <vueper-slide v-for="image of activeMarker.gallery" :key="image.id" :image="image.thumb" />
                            </vueper-slides>
                        </div>
                    </div>

                    <div v-if="activeMarker.content_language.body || activeMarker.file || activeMarker.extern_website || activeMarker.extra_text" class="content-middle">
                        <div v-html="activeMarker.content_language.body"></div>

                        <div v-if="activeMarker.file || activeMarker.extern_website" class="buttons">
                            <a class="btn btn-primary btn-lg" v-if="activeMarker.file" :href="activeMarker.file" target="_blank">Download brochure</a>
                            <a class="btn btn-primary btn-lg" v-if="activeMarker.extern_website" :href="activeMarker.extern_website" target="_blank">Bezoek externe pagina</a>
                        </div>

                        <div class="extra-text" v-html="activeMarker.extra_text"></div>
                    </div>

                    <div class="content-full" v-if="activeMarker.video">
                        <div style="margin-top: 35px;">
                            <iframe class="w-100" :src="'https://www.youtube.com/embed/'+activeMarker.video[0].meta.youtube.videoId" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>

                </div>
            </div>

        </div>


    </div>
</template>

<script>
import {EventBus} from "../event-bus";
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export default {
    components: { VueperSlides, VueperSlide },
    data() {
        return {
            map: null,
            markers: [],

            activeMarker: null
        }
    },
    watch: {
        activeMarker() {
            if (this.activeMarker !== null) {
                setTimeout(() => {
                    let height = document.getElementById('header').offsetHeight;
                    let totalHeight = document.getElementsByClassName('tour-popup')[0].offsetHeight;

                    document.getElementById('body').style.height = (totalHeight-height) + 'px';

                }, 200);
            }
        }
    },
    methods: {
        getNormalizedCoord(coord, zoom) {
            const y = coord.y;
            let x = coord.x; // tile range in one direction range is dependent on zoom level
            // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc

            const tileRange = 1 << zoom; // don't repeat across y-axis (vertically)

            if (y < 0 || y >= tileRange) {
                return null;
            } // repeat across x-axis

            if (x < 0 || x >= tileRange) {
                x = ((x % tileRange) + tileRange) % tileRange;
            }

            return {
                x: x,
                y: y,
            };
        },
        initMap() {
            let normFunc = this.getNormalizedCoord;

            let zoom = 2;
            if (document.getElementsByTagName('body')[0].offsetWidth >= 585) {
                zoom = 3;
            }

            const map = new google.maps.Map(document.getElementById("map"), {
                center: {
                    lat: 0,
                    lng: 0,
                },
                zoom: zoom,
                streetViewControl: false,
                mapTypeControlOptions: {
                    mapTypeIds: ["geertruidskerk"],
                },
            });

            const MapType = new google.maps.ImageMapType({
                getTileUrl: function (coord, zoom) {
                    const normalizedCoord = normFunc(coord, zoom);

                    if (!normalizedCoord) {
                        return "";
                    }

                    const bound = Math.pow(2, zoom);
                    return (
                        "frontend/img/map/zoom_" +
                        zoom +
                        "/x" +
                        normalizedCoord.x +
                        "/y" +
                        (bound - normalizedCoord.y - 1) +
                        ".jpeg"
                    );
                },
                tileSize: new google.maps.Size(256, 256),
                defaultZoom: zoom,
                maxZoom: 4,
                minZoom: 0,
                radius: 1738000,
                name: "Geertruidskerk",
            });
            map.mapTypes.set("geertruidskerk", MapType);
            map.setMapTypeId("geertruidskerk");

            this.map = map;
            this.getMarkers();
        },
        getMarkers() {
            for (let marker of this.markers) {
                marker.setMap(null);
            }
            this.markers = [];
            axios.get('ajax/point')
                .then(response => {
                    let points = response.data.points;
                    this.pages = response.data.pages;

                    for (let point of points) {
                        let marker = new google.maps.Marker({
                            position: new google.maps.LatLng(point.map_point.lat, point.map_point.lng),
                            map: this.map,
                            label: {
                                color: 'black',
                                fontWeight: 'bold',
                                fontFamily: 'Oswald',
                                fontSize: '16px',
                                text: point.content_language.title.toUpperCase()
                            },
                            icon: {
                                labelOrigin: new google.maps.Point(20,58),
                                url: 'frontend/img/content/plattegrond-marker.png',
                            }
                        });

                        this.markers.push(marker);

                        google.maps.event.addListener(marker, 'click', () => {
                            this.activeMarker = point;
                        });
                    }
                });
        },
    },
    mounted() {
        EventBus.$on('maps-loaded', () => {
            this.initMap();
        });
    }
}
</script>

<style scoped>
#map {
    max-width: 100%;
    width: 100%;
    height: 1150px;
}

@media all and (max-width: 585px) {
    #map {
        height: 600px;
    }
}

.w-100 {
    max-width: 100%;
    width: 100%;
    height: 650px;
}

@media all and (max-width: 960px) {
    .w-100 {
        height: 500px;
    }
}

@media all and (max-width: 585px) {
    .w-100 {
        height: 300px;
    }
}

@media all and (max-width: 1275px) {
    .vueperslides {
        padding: 0 40px !important;
    }
}
</style>
