<template>
    <div>
        <div id="actionbar">
            <div class="col-xs-100 h-padding">
                <div class="left">
                    <h1 class="h5 page_title">Digital tour</h1>
                </div>
                <div class="right">
                    <button class="btn btn-primary" @click="placePoint()" v-if="!placingPoint">Nieuw punt</button>
                    <div v-else>
                        <button class="btn btn-error" @click="removeListeners()" >Annuleren</button>
                        <button class="btn btn-success" @click="save()" v-if="!is_editing">Opslaan</button>
                        <button class="btn btn-success" @click="update()" v-else>Opslaan</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="content_wrapper">
            <div class="content-left" ref="left">
                <div class="col-xs-100 col-padding">
                    <div class="panel">
                        <div class="panel-body panel-body--map">
                            <div id="map" :class="{'border': placingPoint}"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-right">
                <div class="col-xs-100 col-padding">
                    <div class="panel" v-show="placingPoint">
                        <div class="panel-header" v-if="is_editing">Punt bewerken</div>
                        <div class="panel-header" v-else>Nieuw punt</div>
                        <div class="panel-body">
                            <p v-if="is_editing" class="text-danger">Het punt is te verplaatsen door te slepen. <strong>Let op: </strong> deze wijziging wordt direct (en los) opgeslagen van andere wijzigingen.</p>
                            <div class="textarea">
                                <label class="form-control form-block">Pagina <small class="text-danger">*</small></label>
                                <select v-model="newPoint.page" class="form-control w-100">
                                    <option v-for="page of pages" :value="page.content.id">{{page.title}}</option>
                                </select>
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div>
                    <div class="panel" v-if="activePoint">
                        <div class="panel-header">{{activePoint.content_language.title}}</div>
                        <div class="panel-body">
                            <qrcode-vue :key="activePoint.map_point.id" :value="activePoint.url" render-as="canvas" id="qr_code"></qrcode-vue>
                        </div>
                        <div class="panel-footer">
                            <div class="row col-padding-sm">
                                <div class="col-padding-sm left">
                                    <button class="btn btn-error" @click="deletePoint(activePoint.map_point.id)">Verwijder</button>
                                </div>
                                <div class="col-padding-sm left">
                                    <button class="btn btn-primary" @click="edit(activePoint)">Bewerk</button>
                                </div>
                                <div class="col-padding-sm left">
                                    <button class="btn btn-primary" @click="download()">Download QR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import {EventBus} from "../event-bus";
import QrcodeVue from 'qrcode.vue';

export default {
    components: {
        QrcodeVue
    },
    data() {
        return {
            map: null,
            markers: [],
            newMarker: null,
            placingPoint: false,
            activePoint: null,
            newPoint: {
                page: null,
            },
            is_editing: false,

            pages: [],
        }
    },
    methods: {
        getNormalizedCoord(coord, zoom) {
            const y = coord.y;
            let x = coord.x; // tile range in one direction range is dependent on zoom level
            // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc

            const tileRange = 1 << zoom; // don't repeat across y-axis (vertically)

            if (y < 0 || y >= tileRange) {
                return null;
            } // repeat across x-axis

            if (x < 0 || x >= tileRange) {
                x = ((x % tileRange) + tileRange) % tileRange;
            }

            return {
                x: x,
                y: y,
            };
        },
        initMap() {
            let normFunc = this.getNormalizedCoord;
            const map = new google.maps.Map(document.getElementById("map"), {
                center: {
                    lat: 0,
                    lng: 0,
                },
                zoom: 1,
                streetViewControl: false,
                mapTypeControlOptions: {
                    mapTypeIds: ["geertruidskerk"],
                },
            });

            const MapType = new google.maps.ImageMapType({
                getTileUrl: function (coord, zoom) {
                    const normalizedCoord = normFunc(coord, zoom);

                    if (!normalizedCoord) {
                        return "";
                    }

                    const bound = Math.pow(2, zoom);
                    return (
                        "../frontend/img/map/zoom_" +
                        zoom +
                        "/x" +
                        normalizedCoord.x +
                        "/y" +
                        (bound - normalizedCoord.y - 1) +
                        ".jpeg"
                    );
                },
                tileSize: new google.maps.Size(256, 256),
                defaultZoom: 1,
                maxZoom: 4,
                minZoom: 0,
                radius: 1738000,
                name: "Geertruidskerk",
            });
            map.mapTypes.set("geertruidskerk", MapType);
            map.setMapTypeId("geertruidskerk");

            this.map = map;
            this.getMarkers();
        },
        placePoint() {
            this.placingPoint = true;
            this.activePoint = null;

            google.maps.event.addListenerOnce(this.map, 'click', event => {
                let marker = new google.maps.Marker({
                    position: event.latLng,
                    map: this.map
                });
                this.markers.push(marker);
                this.newMarker = marker;
            });
        },
        removeListeners() {
            // remove the marker from the map
            if (this.newMarker !== null) {
                this.newMarker.setMap(null);

                let index = this.markers.indexOf(this.newMarker);
                this.markers.splice(index, 1);

                this.newMarker = null;
            }

            google.maps.event.clearListeners(this.map);

            this.newPoint.page = null;

            this.is_editing = false;
            this.placingPoint = false;
        },
        save() {
            if (this.newMarker === null) {
                this.$swal('Let op!', 'Plaats eerst een punt op de kaart.');
                return;
            }

            let formData = new FormData;
            this.newPoint.page !== null ? formData.append('page', this.newPoint.page) : null;
            formData.append('lat', this.newMarker.getPosition().lat());
            formData.append('lng', this.newMarker.getPosition().lng());

            axios.post('../ajax/point', formData)
            .then(() => {
                this.removeListeners();
                this.getMarkers();
            })
            .catch(e => {
                if (e.response.status === 422) {
                    this.error = e.response.data;
                    this.$swal('Oeps', 'Zorg dat alle verplichte velden ingevuld zijn.', 'error');
                }else {
                    this.$swal('Oeps', e.response.data, 'error');
                }
            });
        },
        getMarkers() {
            for (let marker of this.markers) {
                marker.setMap(null);
            }
            this.markers = [];
            axios.get('../ajax/point')
            .then(response => {
                let points = response.data.points;
                this.pages = response.data.pages;

                for (let point of points) {
                    let marker = new google.maps.Marker({
                        position: new google.maps.LatLng(point.map_point.lat,point.map_point.lng),
                        map: this.map
                    });

                    this.markers.push(marker);

                    google.maps.event.addListener(marker, 'click', () => {
                        this.activePoint = point;
                        this.activePoint.markerIndex = this.markers.indexOf(marker);
                        this.activePoint.url = window.location.protocol + '//' + window.location.host + '/' + this.activePoint.content_language.meta_url;
                    });


                }
            });
        },
        edit(point) {
            this.is_editing = point.map_point;
            this.placingPoint = true;
            this.activePoint = null;

            this.newPoint.page = point.map_point.page_id;

            let marker = this.markers[point.markerIndex];
            marker.setDraggable(true);
            google.maps.event.addListener(marker, 'dragend', () => {
                console.log(point);
                axios.patch('../ajax/point?id='+point.map_point.id, {
                    lat: marker.getPosition().lat(),
                    lng: marker.getPosition().lng()
                });
            });
        },
        update() {
            axios.put('../ajax/point?id='+this.is_editing.id, {
                page: this.newPoint.page,
            })
            .then(() => {
                this.removeListeners();
                this.is_editing = false;
                this.placingPoint = false;
                this.getMarkers();
            })
            .catch(e => {
                if (e.response.status === 422) {
                    this.error = e.response.data;
                    this.$swal('Oeps', 'Zorg dat alle verplichte velden ingevuld zijn.', 'error');
                }else {
                    this.$swal('Oeps', e.response.data, 'error');
                }
            });
        },
        deletePoint(id) {
            axios.delete('../ajax/point?id='+id)
            .then(() => {
                this.removeListeners();
                this.activePoint = null;
                this.getMarkers();
            })
        },
        download() {
            let download = document.createElement('a');
            download.download = 'qr-code.png';
            download.href = document.getElementById('qr_code').toDataURL('image/png');
            download.click();
        }
    },
    mounted() {
        EventBus.$on('maps-loaded', () => {
            this.initMap();
        });
    },
}
</script>

<style scoped>
.panel-body--map {
    padding: 0;
}

.panel-body--map div {
    border-radius: inherit;
}

#map {
    height: 500px;
}

.content-left {
    transition: all 0.5s ease;
}

.w-100 {
    width: 100%;
}

.border {
    border: solid 2px #b71818;
    margin: -1px;
}

.text-danger {
    color: #cf1313;
}
</style>
